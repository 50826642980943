<template>
  <div>
    <reset />
  </div>
</template>
<script>
import Reset from '@/components/system/Auth/Reset'
export default {
  components: {
    Reset,
  },
}
</script>
